Cake.create('upload-file','#upload-file',{
    root:'#upload-file-container',
    handlers:{
        async destroy(){
            await this.reset();
        },
        async isConnected(e){
            const {emit:{storage}} = e;

            if(storage == 's3'){
                await this.$scope.set('gameFiles',[
                    {title:'Game Files',field:'data', tag:'input-multiple'},
                ]);
            } else if (storage == 'server'){
                await this.$scope.set('gameFiles',[
                    {title:'Unity Data',field:'data', tag:'input-solo'},
                    {title:'Unity Framework',field:'framework', tag:'input-solo'},
                    {title:'Unity Wasm',field:'wasm', tag:'input-solo'},
                    {title:'Unity Loader',field:'loader', tag:'input-solo'},
                ]);
            };
        }
    },
    subscribe:{
        upload:{
            renderUploadFile(storage){
                console.log(storage);
                this.fire.destroy().then(()=>{
                    this.render({emit:{storage}});
                });
            }
        }
    },
});