Cake.create('login-container','#login-container',{
    root:'body',
    handlers:{
        destroy(){
            this.reset();
        },
        isConnected(){
            console.log('white is connected')
        },
    },
    subscribe:{},
});