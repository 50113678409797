Cake.create('radio', '#radio', {
    // animate:{
    //     render:'ease-in',
    // },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(){
            // return this.html.animate('slideOutRight',{duration:100, }).then(()=>{
            //     this.html.remove();
            // });
            this.$scope.set('radios', null);
            return this.reset();
        },
        async isConnected(e){

            const config = await this.fire('getUiConfig', this.data.menu);
            const radio = config.radio;



            this.$scope.set('radios', radio);
        },
        renderRadio(obj){
            let {root} = obj;

            if (root.children.namedItem('radio-container')){

                this.fire.destroy();
            } else {
                this.fire.destroy().then(()=>{

                    this.render({
                        root,
                    });
                });
            }
        },
        edit(e){

            this.fire('editRecord', e);
        },
        'delete':function(e){
            // this.fire('spinnerRender');
            // this.fire('notifyConfirmDelete', {data:e});
            this.fire('deleteRecord',e);
        },

        enable(e){
            console.log(e);
            if(this.$router.name == 'maintenance'){
                this.fire('enableGame',e);
            };
        },
        disable(e){
            console.log(e);
            if(this.$router.name == 'maintenance'){
                this.fire('disableGame',e);
            };
        },

        view(e){
            let a = document.createElement('a');
            a.href = `/view/${this.data.activeUI}/${e._id}`;
            a.target = '_blank';
            a.click();
        },
        async validate(e){
            let params = Object.assign(e, {tbl:(await this.$globalScope.get('uiConfig')).menu})
            // this.fire('spinnerRender');
            return this.fire('validate', params);
        },
        
        profile(e){
            const {_id} = e;
            const a = document.createElement('a');
            a.target = '_blank';
            a.href = '/profile/member/'+_id;
            a.click();
        },
        close_bet(e){
            let {id} = e;
            return this.fire('closeBet',id);
        }
    },
    subscribe:{
        table:{
            tableRowClick(obj){

                this.fire.renderRadio(obj);
            },
            radioClicked(e){
                this.fire[e.action](e);
            },
            destroy(e){
                return this.fire.destroy();
            }
        },
    },
});