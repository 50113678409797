Cake.create('axios',null,{
    type:'model',
    handlers:{
        async upload(e){
            const {options, data:formData} = e;
            await this.fire('spin','upload');


            const uploadUrl = await this.fire('getUploadUrl');

            // console.log(11, uploadUrl);
            // return;
            const _this = this;
            const config = {
                onUploadProgress: function(progressEvent) {
                    var percent =( Math.round((progressEvent.loaded * 100) / progressEvent.total))+' %';


                    _this.fire('spinProgress', percent);

                    if (percent == 100){
                        
                    };
                }
            };
            let path = null;


            switch (options.device){
                case 'desktop':{
                    path = (options.storage == 'server')?'upload-files':'upload-webassetbundle';
                } break;
                case 'mobile':{
                    path = (options.storage == 'server')?'upload-files':'upload-webassetbundle';
                } break;
                case 'android':{
                    path = 'upload-assetbundle';
                } break;
                case 'ios':{
                    path = 'upload-assetbundle';
                } break;
            };
   
            let query = this.utils.toUrlSearchParams(options);
            
            console.log(query);

            await axios.post(`${uploadUrl}/upload/${path}?${query}`, formData, config)
                .then(res => console.log(res))
                .catch(err => console.log(err))

            await this.fire('spinout','upload');
        },
    },
    subscribe:{
        upload:{
            upload(data){
                this.fire.upload(data);
            }
        },
    },
});