Cake.create('alert', null, {
    handlers:{},
    subscribe:{
        error:{
            components:['paginate','api','table'],
            handler(e){
                return swal.fire({
                    title:'Error',
                    text:e|| 'error',
                    icon:'error',
                });
            }
        },
        success:{
            components:['paginate','api','table'],
            handler(e){
                return swal.fire({
                    title:'Success',
                    text:e|| 'successful',
                    icon:'success',
                });
            }
        },
        warning:{
            components:['paginate','api','form','table'],
            handler(e){
                return swal.fire({
                    title:'Warning',
                    text:e|| 'warning',
                    icon:'warning',
                });
            }
        },

    },
})