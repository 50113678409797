Cake.create('paginate','#paginate',{
    root:".form-container",
    data(){
        this.filter = {};
    },
    handlers:{
        destroy(){
            this.data.filter = {};
            this.reset();
        },
        submit(e){
            const json = this.utils.toFormData(e.target,{json:true});
            if(json.size && Number(json.size) > 1000){
                this.fire('error','size must not more than 1000');
                throw new Error('error');
            };
            if(json.page && Number(json.page) < 0){
                this.fire('error','page must be greater than 1');
                throw new Error('error');
            };
            return {...json, ...this.data.filter};
        },
        async isConnected(e){
            const filter = await this.fire('getCurrentFilter');
            this.data.filter = filter;
            let o = [];
            for (let key in filter){
                if(filter.hasOwnProperty(key)){
                    o.push({content:`${key}=${filter[key]}`}); 
                };
            };

            await this.$scope.set('summary', o);
            const summary = await this.fire('getDownloadSummary', filter);
            await this.$scope.set('stat', `Total record count : ${summary}`);

            if(Number(summary) && Number(summary) > 1000){
                this.$scope.set('size',1000);
            } else {
                this.$scope.set('size',summary);
            };
            this.$scope.set('page',1);

        }
    },
    subscribe:{
        modal:{
            paginate(e){
                this.render();
            },
            destroy(e){
                this.fire.destroy();
            }
        }
    }
});