Cake.create('logsfilter', '#logsfilter', {
    root:".modal-wrap",
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(){
            this.reset();
            this.$scope.hasfilter = null;
        },
        submit(e){
            e.preventDefault();
            let o = {};
            let form = e.target;
            let data = new FormData(form);
            for (let [key, value] of data.entries()){
                o[key] = value;
            };
            return o;
        }
    },
    subscribe:{
        modal:{
            renderLogsFilter(){
                this.render();
            },
            destroy(e){
                this.fire.destroy();
            }
        },
        
    },
});