Cake.create('bet_summary', '#bet-summary', {
    root:'.summary-container',
    data(){
        this.search = {};
        
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(){
            this.reset();
        },
        renderSummary(r){
            if(r && Object.keys(r).length < 4){
                r = {
                    number_placed_bets:0,
                    winloss:0,
                    total_placed_bet:0,
                    total_valid_bets:0,
                }
            };
            
            this.$scope.set('number_placed_bets',r.number_placed_bets || 0);
            this.$scope.set('winloss',accounting.formatMoney(r.winloss  || 0, {
                symbol:'PHP ',
                thousand:',',
                decimal:'.',
            }));
            this.$scope.set('total_placed_bet',accounting.formatMoney(r.total_placed_bet  || 0, {
                symbol:'PHP ',
                thousand:',',
                decimal:'.',
            }));

            this.$scope.set('total_valid_bets', accounting.formatMoney(r.total_valid_bets  || 0, {
                symbol:'',
                thousand:',',
                decimal:'.',
            }));
        },
        async updateFilters(){
            let filters = await this.fire('filters');

            this.data.search = {};
            filters.forEach(filter=>{
                let [key, value] = Object.entries(filter)[0];
                this.data.search[key] = value;
            });
            return filters;
        },
        async isConnected(e){
            await this.fire.updateFilters();
            await this.fire('getBetSummary',{ search:this.data.search });
        }
    },
    subscribe:{
        sidebar:{
            renderSummary(e){
                this.render();
            },
            destroyggr(e){
                this.fire.destroy();
            },
        },
        api:{
            setBetSummary(obj){
                if (obj.data){
                    this.fire.renderSummary(obj.data);
                };
            }
        },

        fuzzy:{
            filterByDateRange(name){
                if(['betting_logs'].includes(this.data.menu)){
                    this.data.search.date_range = name;
                    this.fire('getBetSummary',{search:this.data.search});
                };
            },
        },
        updateFilters:{
            components:['fuzzy','fuzzy_summary'],
            async handler(e){
                await this.fire.updateFilters();
                await this.fire('getBetSummary',{ search:this.data.search });
            }
        }
    },
});