Cake.create('filter_by','#filter-by',{
    root:'.filter-by-container',
    handlers:{
        destroy(e){
            this.reset();
        },
        async isConnected(){
            await this.$globalScope.set('filter_by', 'many');
        },
        async change(e){
            let filter_by = e.target.value;
            await this.$globalScope.set('filter_by', filter_by);
            
        }
    },
    subscribe:{
        modal:{
            filter_by(e){
                this.render();
            },
            destroy(){
                this.fire.destroy();
            }
        },
        'filter-form':{
            renderFilterBy(conf){
                this.render({emit:conf})
            }
        }
    },
});