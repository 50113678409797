Cake.create('filter-form', '#filter-form', {
    root:".form-container",
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(){
            this.reset();
            this.data.controls = null;
        },
        async isConnected(){
            let tbl = this.data.menu;
            let filter_by = await this.$globalScope.get('filter_by');

            console.log('filter form is connected');

            let controls = null;

            if(tbl == 'betting_logs'){
                console.log( await this.fire('getUiConfig', this.data.menu));
                let {filterFields:_controls, menu} = await this.fire('getUiConfig', this.data.menu);

             

                controls = JSON.parse(JSON.stringify(_controls));

                if(filter_by == 'solo'){
                    controls = controls.filter(item=>{
                        return item.field == 'id';
                    });
                } else if (filter_by == 'many') {
                    controls = controls.filter(item=>{
                        return item.field != 'id';
                    });
                };
            } else {

            };
            if(controls){
                this.data.controls = controls;

                await this.$scope.set('controls', controls);
                await this.fire.formOptions(tbl);
            };
        },
        async formOptions(tbl){
            if(tbl == 'betting_logs'){

                // const uniqueUsername = await this.fire('uniqueUsername',tbl); 
                // const uniqueGameType = await this.fire('uniqueGameType',tbl);
                
                // console.log(52,uniqueGameType);
                // console.log(53,uniqueUsername);

                // await this.fire.renderSelectUsername();
                // await this.fire.renderSelectGameType();
            }
        },
        submit(e){
            const json = this.utils.toFormData(e.target,{json:true});

            const data = this.data.controls.reduce((accu, iter)=>{
                let {field, type} = iter;
                let value = json[field];

                if(value != ""){
                    if(type == 'datetime-local'){
                        let [date, time] = value.split('T');
                        if(field.includes('_from')){
                            value = `${date} ${time}:00`;
                        } else if(field.includes('_to')) {
                            value = `${date} ${time}:59`;
                        }

                    };
                    accu[field] = value;
                };


                return accu;
            }, {});

            return data;

        }
    },
    subscribe:{
        modal:{
            // form(){
            //     this.render();
            // },
            destroy(e){
                // console.log('modal is destroyed');
                this.fire.destroy();
            }
        },
        filter_by:{
            change(){
                this.fire.destroy().then(()=>{
                    this.render();
                });
            }
        },
        toolbar:{
            async filter(trigger){
                await this.fire('renderModal',{mode:'filter'});
                // await this.fire('renderFilterBy',{mode:'filter'});
                await this.render({emit:{mode:'filter'}});
            },
        }
        
    },
});