Cake.create('form', '#form', {
    root:".form-container",
    role:'form',
    state:{},
    watch:true,
    router(){
        this.data.menu = this.$router.name;
    },
    validate:{
        date_type(e){
            // this.fire('renderDateRange',e.target.value);
        },
        bet_filter(e){

            this.fire.fuzzyFormOptions({mode:'fuzzy',bet_filter:e.target.value});
        }
    },
    data(){
        this.bet_filter = [
            {value:'dynasty',display:'Dynasty'},
            {value:'gamesmart',display:'GameSmart'}
        ];
        this.channel_type = [
            {value:'',display:''},
            {value:'lan',display:'Land'},
            {value:'rgp',display:'RGP'},
        ];
    },
    handlers:{
        destroy(){
            this.reset();
            this.data.controls = null;
        },
        async isConnected(e){
            let {emit:{mode, data}, } = e;
            let tbl = this.data.menu;
            this.data.mode = mode;
            // let filter_by = await this.$globalScope.get('filter_by');

          
            let {formFields:_controls, menu} = await this.fire('getUiConfig', this.data.menu);
            let controls = JSON.parse(JSON.stringify(_controls));

            if(mode == 'add-record'){
                controls = controls.filter(control=>{
                    return control.form && control.form.includes(mode);
                });
            } else if (mode == 'edit-record'){
                // console.log(controls);
                controls = controls.filter(control=>{
                    return control.form && control.form.includes(mode);
                });
            } else if (mode == 'fuzzy'){


                controls = controls.filter(control=>{
                    return control.form && control.form.includes(mode);
                }).map(item=>{
                    let options = this.data[item.field];
                    if(options){
                        item.options = options;
                    };
                    return item;
                });

            };

            // console.log(69, controls);

            
            if(controls){
                this.data.controls = controls;
                
                await this.$scope.set('controls', controls);
                
            };

            // console.log({mode, data});

            await this.fire.fuzzyFormOptions({mode});
            await this.fire.formOptions(mode);
            await this.fire.plotData({mode, data});

        },
        async plotData({mode, data}){
            let tbl  = this.data.menu;
            // console.log(mode, data);
            if(mode != 'edit-record'){return;};
            let {_id} = data;
            let record = this.data.forUpdate = await this.fire('getRecord',_id);
            
            let form =this.$form();
            // console.log(record);
            this.data.controls.forEach(control=>{
                if(record[control.field] != undefined){
                    let el = form[control.field];
                    el.value = record[control.field];
                };
            });

            if(mode == 'edit-record' && tbl == 'user'){
                const permissions = (await this.fire('getPermissions')).map(item=>{
                    return {label:item.name, value:item._id}
                });
                await this.fire('getUserPermissions', this.data.forUpdate.role_id).then(res=>{
                    const selectedpermissions = res.map(item=>{
                        const {_id} = item;
                        const permission = permissions.find(item=>{
                            return item.value == _id;
                        });
                        return permission.value || null;
                    });
                    this.fire('renderSelectPermissionID', {
                        options:permissions, 
                        selectedOptions:selectedpermissions
                    }); 
                });
            };
        },
        async fuzzyFormOptions({mode,bet_filter}){

            if(mode != 'fuzzy'){
                return;
            };
            if(!bet_filter){
                let options, first = this.data.bet_filter;
                bet_filter = first[0].value;//dynasty or gamesmart
            };

            await this.fire.populateGameType(bet_filter);
        },
        async populateGameType(type){
            let gt = await this.fire('gameTypes', type);

            if(gt){
                gt = gt.map(item=>{
                    return {label: item.display, value:item.id, field:'game_type'};
                });
                // console.log(135, gt);
                this.fire('renderGameType',{options:gt});
            };
        },
        async formOptions(mode){
            let tbl  = this.data.menu;
            if(tbl == 'user'){
                const permissions = (await this.fire('getPermissions')).map(item=>{
                    return {label:item.name, value:item._id}
                });
                const roles = (await this.fire('getRoles')).map(item=>{
                    return {display:item.description, value:item._id}
                });

                // console.log(101, permissions, roles);

                if(mode == 'add-record'){

                    await this.$scope.set('formOptions',{
                        role_id:roles
                    });
                };



                // if(mode == 'edit-record'){
                //     await this.fire('renderSelectPermissionID', permissions);  
                // } else if(mode == 'add-record') {
                //     await this.fire('renderSelectPermission', permissions);
                // };
                await this.fire('renderSelectPermission', permissions);
            } else if(tbl == 'ggr'){

            }
              
            
        },
        async submit(e){
            let tbl = this.data.menu;
            const json = this.utils.toFormData(e.target,{json:true});

            if(tbl == 'login'){
                return this.fire('login',json).then(res=>{
                    if(res.status == 1){
                        let data = Object.assign({},{
                            token:res.data.token,
                            role:res.data.role.description,
                            data:res.data,
                        });
                        this.$router.login(data);
    
                        // this.$router.goTo();
                    } else if (res.status == 2){
                        this.fire('warning',res.message);
                    } else if (res.status == 0){
                        this.fire('error',res.message);
                    };
                });
            };

            if(tbl == 'user'){
                let so = await this.fire('selectedOptions');
                so = so.map(item=>{
                    return item.value;
                });
                json.permission_id = so;
            } else {

            };


            // console.log(json);
            // console.log(this.data.forUpdate);

            if(this.data.mode == 'add-record'){
                this.fire('addRecord', json);
            } else if (this.data.mode == 'edit-record'){
                json._id = this.data.forUpdate._id;
                this.fire('editRecord', json);
            } else if (this.data.mode == 'fuzzy'){
                let selectedOptions = await this.fire('selectedOptions');
                if(selectedOptions){
                    json.game_type_selected = selectedOptions.map(item=>item.label);
                };
                this.fire('fuzzySearch',json).then(()=>{
                    this.fire.destroy().then(()=>{
                        this.fire('destroyDialog');
                    })
                });
            };

        }
    },
    subscribe:{
        modal:{
            form(){
                this.render();
            },
            destroy(e){
                // console.log('modal is destroyed');
                this.fire.destroy();
            }
        },
        filter_by:{
            change(){
                this.fire.destroy().then(()=>{
                    this.render();
                });
            }
        },
        toolbar:{
            addRecord(){
                // this.fire('renderModal',{mode:'add-record'}).then(()=>{
                //     this.render({emit:{mode:'add-record'}});
                // });
                this.fire('dialog',{title:'Add Record',type:'form',message:''}).then(()=>{
                    this.render({root:'.dialog-form-container',emit:{mode:'add-record'}});
                });
            },
        },
        radio:{
            editRecord(e){
                this.fire('dialog',{title:'Edit Record',type:'form',message:''}).then(()=>{
                    this.render({root:'.dialog-form-container',emit:{mode:'edit-record', data:e}});
                });
            }
        },
        fuzzy:{
            filterRecord(e){
                this.fire('dialog',{title:'Filter',type:'form',message:''}).then(()=>{
                    this.render({root:'.dialog-form-container',emit:{mode:'fuzzy'}});
                });
            },
        },
        dialog:{
            destroyForm(){
                // console.log('destroy form');
                this.fire.destroy();
            },
        }
    },
});