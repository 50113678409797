let conf = {};
if(process.env.NODE_ENV == 'development'){
    conf.SOCKET_URL = 'https://uat.888bingo.ph';
} else if (process.env.NODE_ENV == 'production'){
    conf.SOCKET_URL = 'https://888bingo.ph';
    
} else if (process.env.NODE_ENV == 'local'){
    conf.SOCKET_URL = 'http://localhost:5001';
};


module.exports = conf;