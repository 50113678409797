Cake.create('spinner', '#spinner', {
    root:'BODY',
    handlers:{
        destroy(id){
            // console.log('destroy spinner');
            // let find = this.renderQueing.find(item=>{
            //     return item.id = 'tableData';
            // });

            // console.log(find);

            this.fire('tableIsLoaded').then(res=>{
                // console.log(res);
            })

            return this.reset({id}).then(()=>{
                
            });
        },
        click(){
            this.fire.destroy();
        }
    },
    subscribe:{
        spin:{
            components:['api','table','form','axios','bet_summary','ggr'],
            handler(id){
                // console.log(`render ${id}`);
                this.renderQue({id});
            }
        },
        spinout:{
            components:['api','table','form','axios','bet_summary','ggr'],
            handler(id){
                this.fire.destroy(id);
            }
        },
        axios:{
            spinProgress(perc){
                this.$scope.set('progress', perc);
            }
        }
    },
});