Cake.create('modal', '#modal', {
    root:'BODY',
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(e){
            this.reset();
            
        },
        async isConnected(e){
            // console.log(e.emit);
            let {emit:{mode}, } = e;
            let tbl = this.data.menu;

            
            if(mode == 'add-record'){
                await this.$scope.set('name', `Add new record ${tbl}`);
            } else if (mode == 'edit-record'){
                await this.$scope.set('name', `Edit record ${tbl}`);
                if(tbl == 'user'){
                    this.container.form.classList.add('edit-user');
                }
            } else if (mode == 'filter'){
                await this.$scope.set('name', `Filter records`);
            } else if (mode == 'upload'){
                await this.$scope.set('name', `Upload Game File`);
            };
            

            // if(trigger == 'filter'){
            //     this.$scope.set('name', this.$router.display);
            // } else if (trigger == 'download'){
            //     this.$scope.set('name', 'Paginate Download');
            // };

            // if (tbl == 'logs'){
            //     switch(trigger){
            //         case 'filter':{
            //             await this.fire('filter_by');
            //             await this.fire('form');
            //         } break;
            //     };
            // } else if (tbl == 'history'){
            //     switch(trigger){
            //         case 'filter':{
            //             await this.fire('form');
            //         } break;
            //     };
            // };

            // if(trigger == 'download'){
            //     await this.fire('paginate');
            // };            
        }
    },
    subscribe:{


        renderModal:{
            components:['filter-form','form','upload'],
            handler(conf){
                console.log(conf);
                this.render({emit:conf});
            }
        },
    },
});