Cake.create('fuzzy_date_range','#fuzzy-date-range',{
    root:'.fuzzy-date-range-container',
    data(){
        this.controls = [
            {field: 'start_datetime', title: 'Start Date/Time', tag:'input',type: 'datetime-local', placeholder:'end datetime',category:'datetime'},
            {field: 'end_datetime', title: 'End Date/Time', tag:'input',type: 'datetime-local', placeholder:'start datetime',category:'datetime'},
            {field: 'date_range', title: 'Date Range', tag:'select-auto',type: 'text', form:['fuzzy'],category:'daterange',options:[
                {value:'today', display:'Today'},
                {value:'yesterday', display:'Yesterday'},
                {value:'current_week', display:'This Week'},
                {value:'last_week', display:'Last Week'},
                {value:'current_month', display:'This Month'},
                {value:'last_month', display:'Last Month'},
            ]},
        ]
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(e){
            let {emit:data} = e;
            let controls = this.data.controls.filter(item=>{
                return item.category == data.type;
            });


            console.log(20, controls);

            this.$scope.set('controls', controls).then(()=>{
                console.log('fuzzy date range is rendered');
            });
        }
    },
    subscribe:{
        form:{
            async renderDateRange(type){
                if(!this.data.isrender){
                    await this.render({emit:{type}});

                    this.data.isrender = true;
                } else {
                    await this.fire.destroy();
                    await this.render({emit:{type}});
                }
            }
        }
    },
});