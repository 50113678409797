Cake.create('bet_filter', '#bet_filter',{
    root:".bet-filter-container",
    // animate:{
    //     bet_filter:{
    //         render:{keyframes:['appear']},
    //         remove:{keyframes:['disappear']},
    //     },
    // },
    toggle:{
        activeFilter:{
            ns:`bet_filter`, cls:'is-active', basis:'data-name',
        },
    },
    handlers:{
        destroy(){
            this.reset();
        },
        async isConnected(e){
            const filters = await this.fire('getBetFilter');


            await this.$scope.set('bet_filters', filters);
            const current = await this.toggler.recall('activeFilter');

            
            if(!current){
                this.toggler('activeFilter', filters[0].id);
                this.$globalScope.set('bet_filter', filters[0].id);
            };
        },
        async click(e){
            let target = e.target.closest('[data-toggle]');
            if(!target){return;};
            let name = target.dataset.name;
            await this.toggler('activeFilter', name);
            await this.$globalScope.set('bet_filter', name);
            return name; 
        }
    },
    subscribe:{},
});