Cake.create('tabs','#tabs',{
    root:'.tabs-container',
    toggle:{
        activeTabMenu:{
            sel:`.tabs>ul>li`, cls:'is-active', basis:'data-name',
        },
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        softReload(){
            console.log('tabs soft reload');
        },
        async isConnected(){
            let auth = this.$router.auth();
            if(!auth)return;
            let username = auth.data.username;
            let token = auth.data.token;

            let permission = await this.fire('getUserPermissions');

            // console.log(25, permission);

            this.data.permission = permission.filter(item=>{
                return item.name.includes('sidebar.');
            }).map(item=>{
                item.name = item.name.split('sidebar.')[1];
                return item;
            });
        

            await this.$scope.set('username',username);
            await this.$scope.set('tabLinks',this.data.permission);
            await this.toggler('activeTabMenu', this.data.menu);

        },
        click(e){
            let target = e.target.closest('li');
            let next = target.dataset.name;


            // let menu = this.data.menu;
            this.toggler('activeTabMenu', next);
        }
    },
    subscribe:{},
});