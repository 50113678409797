Cake.create('dashboard','#dashboard',{
    root:'.dashboard-container',
    data(){
        this.bar = [];
    },
    handlers:{

        destroy(){
            this.data.chartSummaryActiveUsers && this.data.chartSummaryActiveUsers.destroy && this.data.chartSummaryActiveUsers.destroy();
            this.data.chartActiveUsers && this.data.chartActiveUsers.destroy && this.data.chartActiveUsers.destroy();
            this.reset();
        },
        isConnected(){
            if(this.$router.name == 'dashboard'){
                this.fire.renderRealtimeLineChart({name:'chartActiveUsers',selector:'activeUsers'});
                this.fire.renderBarChart({name:'chartSummaryActiveUsers',selector:'activeUserssummary'});
            };
        },
        renderBarChart({name,selector,}){
            this.data.chartSummaryActiveUsers = new Chart(document.getElementById(selector), {
                type: 'bar',
                data: {
                    labels:[
                        'Lobby Users',
                        'Fortune 30',
                        'Peryahan',
                        'Golden Era',
                        'Gold Farm',
                        'Sea Riches',
                        'Pirate Babes',
                        'Dragon vs Tiger',
                        '13 Ball',
                        'Swertres',
                        'Pares',
                        'Lucky 9',
                        'Fortune 21',
                        'Ball Poker 3'
                    ],
                    datasets: [{
                        label: ['Active Users'],
                        data: [],
                        backgroundColor: [
                            'rgba(255,99,132,0.2)',
                            'rgba(30,99,132,0.2)',
                            'rgba(30,20,132,0.2)',
                            'rgba(60,20,132,0.2)',
                            'rgba(60,120,132,0.2)',
                            'rgba(200,120,132,0.2)',
                            'rgba(200,20,132,0.2)',
                            'rgba(200,20,10,0.2)',
                            'rgba(100,20,10,0.2)',
                            'rgba(50,80,80,0.2)',
                            'rgba(10,80,200,0.2)',
                            'rgba(50,10,200,0.2)',
                            'rgba(220,80,100,0.2)',
                            'rgba(180,160,70,0.2)',
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(30,99,132,1)',
                            'rgba(30,20,132,1)',
                            'rgba(60,20,132,1)',
                            'rgba(60,120,132,1)',
                            'rgba(200,120,132,1)',
                            'rgba(200,20,132,1)',
                            'rgba(200,20,10,1)',
                            'rgba(100,20,10,1)',
                            'rgba(50,80,80,1)',
                            'rgba(10,80,200,1)',
                            'rgba(50,10,200,1)',
                            'rgba(220,80,100,1)',
                            'rgba(180,160,70,1)',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        },
        renderRealtimeLineChart({name,selector}){
            this.data[name] = new Chart(document.getElementById(selector), {
                type:'line',
                label:'Active Users',
                data: {

                    datasets:[
                        {
                            id:'lobby',
                            label:'Lobby Users',
                            data:[],
                            backgroundColor:'rgba(255,99,132,0.2)',
                            borderColor:'rgba(255,99,132,1)',
                            borderWidth:1,
                        },
                        {
                            id:'bingo-fortune_30',
                            label:'Fortune 30',
                            data:[],
                            backgroundColor:'rgba(30,99,132,0.2)',
                            borderColor:'rgba(30,99,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-perya',
                            label:'Peryahan',
                            data:[],
                            backgroundColor:'rgba(30,20,132,0.2)',
                            borderColor:'rgba(30,20,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-golden_era',
                            label:'Golden Era',
                            data:[],
                            backgroundColor:'rgba(60,20,132,0.2)',
                            borderColor:'rgba(60,20,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-gold_farm',
                            label:'Gold Farm',
                            data:[],
                            backgroundColor:'rgba(60,120,132,0.2)',
                            borderColor:'rgba(60,120,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-sea_riches',
                            label:'Sea Riches',
                            data:[],
                            backgroundColor:'rgba(200,120,132,0.2)',
                            borderColor:'rgba(200,120,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-pirate_babes',
                            label:'Pirate Babes',
                            data:[],
                            backgroundColor:'rgba(200,20,132,0.2)',
                            borderColor:'rgba(200,20,132,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-dragon_vs_tiger',
                            label:'Dragon vs Tiger',
                            data:[],
                            backgroundColor:'rgba(200,20,10,0.2)',
                            borderColor:'rgba(200,20,10,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-13_ball',
                            label:'13 Ball',
                            data:[],
                            backgroundColor:'rgba(100,20,10,0.2)',
                            borderColor:'rgba(100,20,10,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-swertres',
                            label:'Swertres',
                            data:[],
                            backgroundColor:'rgba(50,80,80,0.2)',
                            borderColor:'rgba(50,80,80,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-pares',
                            label:'Pares',
                            data:[],
                            backgroundColor:'rgba(10,80,200,0.2)',
                            borderColor:'rgba(10,80,200,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-lucky_9',
                            label:'Lucky 9',
                            data:[],
                            backgroundColor:'rgba(50,10,200,0.2)',
                            borderColor:'rgba(50,10,200,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-fortune_21',
                            label:'Fortune 21',
                            data:[],
                            backgroundColor:'rgba(220,80,100,0.2)',
                            borderColor:'rgba(220,80,100,1)',
                            borderWidth:1,
                        },{
                            id:'bingo-ball_poker_3',
                            label:'Ball Poker 3',
                            data:[],
                            backgroundColor:'rgba(180,160,70,0.2)',
                            borderColor:'rgba(180,160,70,1)',
                            borderWidth:1,
                        }
                    ],
                },
                options: {
                  scales: {
                    x: {
                      type: 'realtime',
                      realtime:{
                        delay: 1000,
                        frameRate: 120,
                        duration:30000,
                      }
                    },
                    y:{
                        beginAtZero:true,
                    }
                  }
                }
              });
        },
        updateLobbyUsers(e){
            // console.log(211,e);
            if(this.data.chartActiveUsers){
                this.data.chartActiveUsers.data.datasets.forEach(datasets=>{
                    if(datasets.id == 'lobby'){
                        datasets.data.push({
                            x:Date.now(),
                            y:e.data,
                        });
                    };
                });
                this.data.chartActiveUsers && this.data.chartActiveUsers.update('quiet');
            };


            if(this.data.chartSummaryActiveUsers){
                this.data.bar[0] = e.data;
                // console.log(this.data.bar[0],e);
                // let data = this.data.chartSummaryActiveUsers.data.datasets[0].data;
                // let prev = data[0];
                // let same = prev == data;
                // if(!same){
                //     data[0] = e.data;
                //     this.data.chartSummaryActiveUsers.data.datasets[0].data[0] = e.data;
                //     this.data.chartSummaryActiveUsers && this.data.chartSummaryActiveUsers.update('none');
                // };
                // console.log(301,this.data.bar);
                setTimeout(()=>{
                    this.data.chartSummaryActiveUsers.data.datasets[0].data = this.data.bar;
                    this.data.chartSummaryActiveUsers && this.data.chartSummaryActiveUsers.update('none');
                },1000);
            };

        },
        updateGameUsers(e){
            if(this.data.chartActiveUsers){
                for (let game in e){
                    if(e.hasOwnProperty(game)){
                        this.data.chartActiveUsers.data.datasets.forEach(datasets=>{
                            if(datasets.id == game){
                                datasets.data.push({
                                    x:Date.now(),
                                    y:e[game],
                                })
                            }
                        })
                    };
                };
                this.data.chartActiveUsers && this.data.chartActiveUsers.update('quiet');
            };
            if(this.data.chartSummaryActiveUsers){
                let data = [
                    'lobby',
                    'bingo-fortune_30',
                    'bingo-perya',
                    'bingo-golden_era',
                    'bingo-gold_farm',
                    'bingo-sea_riches',
                    'bingo-pirate_babes',
                    'bingo-dragon_vs_tiger',
                    'bingo-13_ball',
                    'bingo-swertres',
                    'bingo-pares',
                    'bingo-lucky_9',
                    'bingo-fortune_21',
                    'bingo-ball_poker_3',
                ].map(item=>{
                    if(e[item]){
                        return e[item];
                    } else {
                        return 0;
                    }
                });


                for (let i = 1; i < 14; i++){
                    this.data.bar[i] = data[i] || 0;
                };

                // this.data.bar[1] = data[1];

                // let prev = this.data.chartSummaryActiveUsers.data.datasets[0].data.slice(1);
                // let same = data.slice(1).every((item,index)=>{
                //     return item == prev[index];
                // });
 
                // console.log(278,prev);
                // console.log(279,data.slice(1));
                // console.log(280, same, e);
                // if(!same){
                //     this.data.chartSummaryActiveUsers.data.datasets[0].data = JSON.parse(JSON.stringify(data));
                //     this.data.chartSummaryActiveUsers.update('none');
                // };

                // console.log(302,this.data.bar);

                setTimeout(()=>{
                    this.data.chartSummaryActiveUsers.data.datasets[0].data = this.data.bar;
                    this.data.chartSummaryActiveUsers.update('none');
                },900);

            };

        }
    },
    subscribe:{
        socket:{
            updateGameUsers(e){
                if(this.$router.name == 'dashboard'){
                    this.fire.updateGameUsers(e);
                };
            },
            updateLobbyUsers(e){
                // console.log(320,e);
                if(this.$router.name == 'dashboard'){
                    this.fire.updateLobbyUsers(e);
                };
            }
        }
    },
});