Cake.create('upload','#upload',{
    // root:'.table-container',
    root:'.form-container',
    role:'form',
    watch:true,
    state:{
        json:{},
    },
    validate:{
        async device(e){
            
            this.state.json.device = e.target.value;
        },
        async game(e){
            await this.fire('getGameStatus',e.target.value);
            this.state.json.game = e.target.value;
        },
        async storage(e){
            this.state.json.storage = e.target.value;
            await this.fire('renderUploadFile', e.target.value);
            let opts = null;
            if(e.target.value == 'server'){
                opts = [
                    {value:'desktop', display:'Desktop'},
                    {value:'mobile', display:'Mobile'},
                ]
            } else if (e.target.value == 's3'){
                opts = [
                    {value:'desktop', display:'Desktop'},
                    {value:'ios', display:'Mobile IOS'},
                    {value:'android', display:'Mobile ANDROID'},
                ]
                
            };

            this.$scope.set('formOptions',{device:opts});
        }
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        async isConnected(){
            await this.$scope.set('gameOptions',[
                {title:'Game Name',field:'game', type:'text', tag:'select-auto',options:[
                    {value:'bingo_perya', display:'Bingo Peryahan'},
                    {value:'fortune_30', display:'Fortune 30'},
                    {value:'gold_farm', display:'Gold Farm'},
                    {value:'golden_era', display:'Golden Era'},
                    {value:'pirate_babes', display:'Pirate Babes'},
                    {value:'sea_riches', display:'Sea Riches'},
                    {value:'bingo_pares', display:'Bingo Pares'},
                    {value:'bingo_swertres', display:'Bingo Swertres'},
                    {value:'dragon_vs_tiger', display:'Dragon vs. Tiger'},
                    {value:'bingo_13_ball', display:'13 Ball'},
                ]},
                {title:'Game Environment',field:'type', tag:'select-auto', type:'text', options:[
                    {value:'demo', display:'Play Free'},
                    {value:'lobby', display:'Play Live'},
                ]},
                {title:'Storage',field:'storage', type:'text', tag:'select-auto', options:[
                    {value:'server', display:'Server'},
                    {value:'s3', display:'S3'},
                ]},
                {title:'Platform',field:'device', type:'text', tag:'select-update', options:[]},
            ]);

            this.$scope.set('formOptions',{device:[
                {value:'desktop', display:'Desktop'},
                {value:'mobile', display:'Mobile'},
            ]});

            await this.fire('renderUploadFile', 'server');

            this.state.json.device = 'desktop';
            this.state.json.game = 'bingo_perya';
            this.state.json.storage = 'server';
            this.state.json.type = 'demo';
            
            
        },
        submit(e){
            let data = this.utils.toFormData(e.target);
            // console.log(data);
            this.fire('upload',{data, options:this.state.json});
        }
    },
    subscribe:{
        toolbar:{
            upload(e){
                this.fire('renderModal',{mode:'upload'}).then(()=>{
                    this.render();
                });
            }
        },
        modal:{
            destroy(){
                this.fire.destroy();
            }
        }
    },
});