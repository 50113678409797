Cake.create('toolbar', '#toolbar', {
    root:'.toolbar-container',
    router(){
        this.data.menu = this.$router.name;
        this.data.dataset = this.$router.display;
    },
    // animate:{
    //     toolbar:{
    //         render:{keyframes:['appear']},
    //         remove:{keyframes:['disappear']},
    //     },
    // },
    toggle:{
        activeToolbarMenu:{
            ns:`tool`, cls:'is-active', basis:'data-name',
        },
    },
    handlers:{

        async destroy(){
            await this.reset();
        },
        isConnected(obj){

            this.fire('getToolbar', this.data.menu).then(res=>{
                return this.$scope.set('toolbars', res);
            }).then(()=>{
                // this.toggler.recall('activeToolbarMenu');
                this.$scope.set('dataset', this.$router.display);
            });
        },
        click(e){
            let target = e.target.closest('[data-toggle]');
            if(!target){return;};
            let name = target.dataset.name;
            this.toggler('activeToolbarMenu', name);

            
            
            if([
                'today',
                'yesterday',
                'current_week',
                'previous_week',
                'current_month',
                'previous_month',
            ].includes(name)){
                this.fire('filterBy', name);
            } else if (name == 'download'){
                this.fire('downloadRemote');
            } else {
                this.fire(name);
            };
        }
    },
    subscribe:{

    },
});