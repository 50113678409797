Cake.create('fuzzy','#fuzzy',{
    root:'.fuzzy-container',
    router(){
        this.data.menu = this.$router.name;
    },
    toggle:{
        activeDt:{
            ns:'dt',sel:'[data-role=date-range]', cls:'is-active', basis:'data-name',
        },
        activeVb:{
            ns:`vb`, cls:'is-active', basis:'data-name',
        },
    },
    handlers:{
        async destroy(){
            await this.fire('removeFuzzySummary');


            await this.reset();
        },
        async isConnected(){

            let a = await this.toggler.recall('activeDt');
            
            
            const date_range = await this.$globalScope.get('date_range');

            if(!date_range){
                this.$globalScope.set('date_range', 'today');
            };
            const bet_filter = await this.$globalScope.get('bet_filter');
            if(!bet_filter){
                this.$globalScope.set('bet_filter', 'dynasty');
            };

            const bet_status = await this.$globalScope.get('bet_status');
            if(!bet_status){
                this.$globalScope.set('bet_status', 'valid');
            };



            let filters = await this.fire.getFilters();


            await this.fire('renderFuzzySummary',filters);


            if(!a){
                this.toggler('activeDt', 'today');
            };


        

            if(this.data.menu == 'betting_logs'){
                let betStatuses = (await this.fire('getUserPermissions')).filter(item=>{
                    return item.name.includes('bet_status.');
                }).map(item=>{
                    
                    let name = item.name.split('bet_status.')[1];
                    return {display:item.description, _id:item._id,field:name};
                });
    
                // console.log(65,betStatuses);
                if(betStatuses){
                    await this.$scope.set('betStatuses',betStatuses);
                };


                let b = await this.toggler.recall('activeVb');
                if(!b){
                    this.toggler('activeVb', 'valid');
                };
            };




        },
        click(){
            this.fire('filterRecord')
        },
        dateRange(e){
            let target = e.target.closest('[data-toggle]');
            if(!target){return};
            let name = target.dataset.name;

            this.toggler('activeDt', name);
            this.$globalScope.set('date_range', name);

            this.fire('filterByDateRange',name);

        },
        betStatus(e){
            let target = e.target.closest('[data-toggle]');
            if(!target){return};
            let name = target.dataset.name;
            console.log(99, name);
            this.toggler('activeVb', name);
            this.$globalScope.set('bet_status', name);

            this.fire('filterByBetStatus',name);
        },
        async getFilters(){
            let f = [];
            let date_range = await this.$globalScope.get('date_range'); 
            let channel_type = await this.$globalScope.get('channel_type'); 
            let game_type = await this.$globalScope.get('game_type'); 
            let game_type_selected = await this.$globalScope.get('game_type_selected'); 
            let bet_filter = await this.$globalScope.get('bet_filter'); 
            let start_date = await this.$globalScope.get('start_date'); 
            let end_date = await this.$globalScope.get('end_date'); 
            let bet_status = await this.$globalScope.get('bet_status'); 

            date_range && (f.push({date_range}));
            channel_type && (f.push({channel_type}));
            game_type && (f.push({game_type}));
            bet_filter && (f.push({bet_filter}));
            game_type_selected && game_type_selected.length  && (f.push({game_type_selected}));
            start_date && (f.push({start_date}));
            end_date && (f.push({end_date}));
            bet_status && (f.push({bet_status}));

            


            return f;
        }
    },
    subscribe:{
        form:{
            fuzzySearch(d){
                let {start_time, start_date, end_time, end_date} = d;
                let now = moment();
                let fnow = now.format('yyyy-MM-DD');

                function parseTime(t){
                    let [h,m] = t.split(":");
                    let s = (m == "59")?"59":"00";
                    return `${h}:${m}:${s}`;
                };

                start_time && (start_time = parseTime(start_time));
                end_time && (end_time = parseTime(end_time));

                let isStartTimeOnly = start_time && !start_date;
                let isEndTimeOnly = end_time && !end_date;

                if(start_time && start_date){
                    d.start_date = `${start_date} ${start_time}`
                } else if(isStartTimeOnly){
                    d.start_date = `${fnow} ${start_time}`;
                } else if (isEndTimeOnly && !isStartTimeOnly){
                    d.start_date = `${fnow} 00:00:00`;
                } else if (start_date){
                    d.start_date = `${start_date} 00:00:00`;
                };

                if(end_time && end_date){
                    d.end_date = `${end_date} ${end_time}`
                } else if(isEndTimeOnly){
                    d.end_date = `${fnow} ${end_time}`;
                } else if(!isEndTimeOnly && isStartTimeOnly){
                    d.end_date = `${fnow} 23:59:59`;
                } else if (end_date){
                    d.end_date = `${end_date} 23:59:59`;
                };

                delete d.start_time;
                delete d.end_time;
                
                for (let key in d){
                    if(d.hasOwnProperty(key)){
                        let v = d[key];

                        if('game_type_selected'){
                            if(v.length){} else {
                                v = "";

                            }
                            this.$globalScope.set(key, v);
                        }

                        if(v && ['date_range','channel_type','game_type','bet_filter'].includes(key)){
                            
                            this.$globalScope.set(key, v);
                        };
                    };
                };

                this.fire.getFilters().then(async (filters)=>{
                    await this.fire('removeFuzzySummary');
                    await this.fire('renderFuzzySummary',filters);
                    await this.fire('updateFilters');
                });
            }
        },
        filters:{
            components:['table', 'ggr','api','bet_summary'],
            handler(e){
                return this.fire.getFilters();
            }
        }
    },
})