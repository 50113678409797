Cake.create('socket', null, {
    type:'model',
    init(){
        window.socket = io(`${conf.SOCKET_URL}/admin`,{
            withCredentials: true,
            'sync disconnect on unload': true, 
            transports: ["websocket"],

        });
        socket.on('connect', (e)=>{
            socket.sendBuffer = [];
            console.log(`game lobby is connected to server socket with socket ${socket.id}`);
            // socket.emit('connected');

        });
        socket.on('/updateGameUsers', (data)=>{
            this.fire.updateGameUsers(data,true);
        });
        socket.on('/updateLobbyUsers', (data)=>{
 
            this.fire.updateLobbyUsers({data},true);
        });
    },
    handlers:{
        updateLobbyUsers(e){
            return e;
        },
        updateGameUsers(e){
            return e;
        }
    },
    subscribe:{

    },
})