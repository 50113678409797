Cake.create('dialog', '#dialog', {
    handlers:{
        async destroy(){
            let t = 100;
            let cont = this.html.getElement();
            return await new Promise((res, rej)=>{
                setTimeout(()=>{
                    cont && cont.classList.remove('is-show');
                },t);
                setTimeout(()=>{
                    res();
                },t+200);
            }).then(async ()=>{


                if(this.data.type == 'form'){
                    await this.fire('destroyForm');
                }

                return this.reset();
            });
        },
        isConnected(e){
            let {emit:data} = e;
            let cont = this.html.getElement();
            setTimeout(()=>{
                cont && cont.classList.add('is-show');
            },100);
            // is-active

            this.data.type = data.type;

            if(data.type != 'form'){
                setTimeout(()=>{
                    this.$scope.set('controls',false);
                },200);
            };

        },
        ok(e){
            this.data.res({isConfirmed:true,});
        },
        cancel(e){
            this.data.res({isConfirmed:false,});
        },
    },
    subscribe:{
        dialog:{
            components:['paginate','api','table','form','fuzzy'],
            async handler(e){
                if(!e){
                    e = {};
                };

                await this.render({
                    emit:{
                        title:e.title,
                        message:e.message,
                        icon:'error',
                        type:e.type
                    },
                    data:{
                        title:e.title,
                        message:e.message,
                        icon:'error',
                        type:e.type
                    }
                });

                if(e.type != 'form'){
                    return new Promise((res, rej)=>{
                        this.data.res = res;
                    }).then(async (data)=>{
                        await this.fire.destroy();
                        return data;
                    });
                };
                return true;

            }
        },
        destroyDialog:{
            components:['form','fuzzy'],
            handler(e){
                this.fire.destroy();
            }
        }

    },
})