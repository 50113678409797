Cake.create('api', null, {
    type:'model',
    utils(self){
        const _fetch = (method)=>{

            return (path, obj)=>{
                let config = {
                    method,
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit, 
                    headers:{},
                };
                if (obj && obj.body){
                    config.body = JSON.stringify(obj.body);
                    config.headers = {'Content-Type': 'application/json',};
                };
                if(obj && obj.headers){
                    config.headers = Object.assign(config.headers,obj.headers);
                };

                if(obj && obj.query){
                    path = `${path}?${this.toUrlSearchParams(obj.query)}`;
                };
                return fetch(path,config).then(res=>{
                    return res.json();
                }).then(res=>{
                    if(res.status == 2){
                        self.fire('warning', res.message);
                    } else if (res.status == 0){
                        self.fire('error',res.message);
                    };
                    return res;
                }).catch(err=>{
                    self.fire('error',err.message);
                });
            };
        };
        this.post = _fetch('POST');
        this.get = _fetch('GET');
        this.delete = _fetch('DELETE');
        this.put = _fetch('PUT');

        this.getToken = function(){
            let auth = self.$router.auth();
            return auth?auth.token:null;

        }.bind(this);
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        verify(token){
            this.fire('spin','verify-auth');
            return this.utils.get(`/api/auth/verify`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(result=>{
                this.fire('spinout','verify-auth');
                return result;
            });
        },
        login(data){
            this.fire('spin','login');
            return this.utils.post('/api/auth/login?encrypt=1',{
                body:{encrypt:btoa(JSON.stringify(data))}
            }).then(result=>{
                this.fire('spinout','login');
                this.fire('success','login successful');
                return result;
            });
        },
        getUserPermissions(role_id){

            role_id = role_id || "";
            let token = this.utils.getToken();
            this.fire('spin','getUserPermissions');
            return this.utils.get(`/api/user/get-user-permissions?role_id=${role_id}`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','getUserPermissions');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        getPermissions(){
            let token = this.utils.getToken();
            this.fire('spin','getPermissions');
            return this.utils.get('/api/permission',{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','getPermissions');
                if(res.status == 1){
                    return res.data;
                };
                return null
            });
        },
        getRoles(){
            let token = this.utils.getToken();
            this.fire('spin','getRoles');
            return this.utils.get('/api/role',{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','getRoles');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        addRecord(data){
            let token = this.utils.getToken();
            this.fire('spin','addRecord');
            return this.utils.post(`/api/${this.data.menu}`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:data,
            }).then(res=>{
                this.fire('spinout','addRecord');
                if(res.status == 1){
                    this.fire('reloadTable');
                    return res.data;
                };
                return null;
            });
        },
        deleteRecord(data){
            let token = this.utils.getToken();
            this.fire('spin','deleteRecord');
            return this.utils.delete(`/api/${this.data.menu}`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:data,
            }).then(res=>{
                this.fire('spinout','deleteRecord');
                if(res.status == 1){
                    this.fire('reloadTable');
                    return res.data;
                };
                return null;
            });
        },
        getRecord(_id){
            let token = this.utils.getToken();
            this.fire('spin','getRecord');
            return this.utils.get(`/api/${this.data.menu}/${_id}`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','getRecord');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        editRecord(data){
            let token = this.utils.getToken();
            this.fire('spin','editRecord');

            let tbl = this.data.menu;

            if(tbl == 'user' && data.permission_id){
                tbl = 'role_permission';
            };

            return this.utils.put(`/api/${tbl}/${data._id}`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:data,
            }).then(res=>{
                this.fire('spinout','editRecord');
                if(res.status == 1){
                    console.log(res.data);
                    this.fire('reloadTable');
                };
                return null;
            });
        },
        getGGRSummary(params){
            let token = this.utils.getToken();
            this.fire('spin','getGGRSummary');
            return this.utils.get(`/api/ggr/summary`,{
                headers:{'Authorization':`Bearer ${token}`},
                query:params,
            }).then(res=>{
                this.fire('spinout','getGGRSummary');
                if(res.status == 1){
                    this.fire('setGGR', res.data);
                    
                };
                return null;
            });
        },
        getBetSummary(params){
            let token = this.utils.getToken();
            this.fire('spin','getBetSummary');
            return this.utils.get(`/api/betting_logs/summary`,{
                headers:{'Authorization':`Bearer ${token}`},
                query:params,
            }).then(res=>{
                this.fire('spinout','getBetSummary');
                if(res.status == 1){
                    this.fire('setBetSummary', res.data);
                    
                };
                return null;
            });
        },
        getStaticToken(){
            let token = this.utils.getToken();
            this.fire('spin','getStaticToken');
            return this.utils.get(`/api/static_token`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','getStaticToken');
                if(res.status == 1){
                    return res.data;
                };
                return null;
            });
        },
        resetStaticToken(){
            let token = this.utils.getToken();
            this.fire('spin','resetStaticToken');
            return this.utils.post(`/api/static_token`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                this.fire('spinout','resetStaticToken');
                if(res.status == 1){
                    console.log(res);
                    return res.data
                };
                return null;
            });
        },
        getGameStatus(game){
            this.fire('spin','getGameStatus');
            console.log(game);
            setTimeout(()=>{
                this.fire('spinout','getGameStatus');
            },1000);
        },
        getUploadUrl(){
            let token = this.utils.getToken();
            return this.utils.get(`/api/other/upload-url?env=${(location.host.includes('localhost'))?'local':'remote'}`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                if(res.status == 1){
                    console.log(res);
                    return res.data
                };
                return null;
            });
        },
        async gameTypes(type){
            let token = this.utils.getToken();
            return this.utils.get(`/api/betting_logs/gametypes/${type}`,{
                headers:{'Authorization':`Bearer ${token}`},
            }).then(res=>{
                if(res.status == 1){
                    return res.data
                };
                return null;
            });
        },
        async downloadRemote(){
            let token = this.utils.getToken();
            let filters = await this.fire('filters');
            let params = {};
            filters.forEach(filter=>{
                let [key, value] = Object.entries(filter)[0];
                params[key] = value;
            });


            // return fetch(`/api/${this.$router.name}/export?page=1&size=1000&search=${JSON.stringify(params)}`,{
            return fetch(`/api/${this.$router.name}/export?search=${JSON.stringify(params)}`,{
                headers:{'Authorization':`Bearer ${token}`}
            }).then(res=>{
                return res.blob()
            }).then((data)=>{
                let url = window.URL.createObjectURL(data);
                var a = document. createElement("a");
                a.href = url
                a.download = this.$router.display;
                a.click();
                window.URL.revokeObjectURL(url);
            });


        },
        async enableGame(e){
            this.fire('spin','enableGame');
            let token = this.utils.getToken();
            return this.utils.post(`/api/maintenance`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:{id:e.id,is_active:1},
            }).then(res=>{
                this.fire('spinout','enableGame');
                if(res.status == 1){
                    this.fire('setGGR', res.data);
                    
                };
                this.fire('reloadTable');
                return null;
            }).catch(err=>{
                this.fire('spinout','enableGame');
            });
        },
        async disableGame(e){
            this.fire('spin','disableGame');
            let token = this.utils.getToken();
            return this.utils.post(`/api/maintenance`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:{id:e.id,is_active:0},
            }).then(res=>{
                this.fire('spinout','disableGame');
                if(res.status == 1){
                    this.fire('setGGR', res.data);
                    
                };
                this.fire('reloadTable');
                return null;
            }).catch(err=>{
                this.fire('spinout','disableGame');
            });
        },
        async closeBet(bet_id){
            this.fire('spin','closeBet');
            let token = this.utils.getToken();
            return this.utils.post(`/api/betting_logs/close-bet`,{
                headers:{'Authorization':`Bearer ${token}`},
                body:{id:bet_id},
            }).then(async (res)=>{
                await this.fire('spinout','closeBet');
                if(res.status){
     
                    await this.fire('success','successfully closed the bet');
                    await this.fire('reloadTable');
                } else {
                    this.fire('error',res.message);
                }
                return null;
            }).catch(err=>{
                this.fire('spinout','closeBet');
                this.fire('error',err.message);
            });
        }
    },
    subscribe:{
        paginate:{
            submit(filter){
                console.log(filter);
                switch(this.data.menu){
                    case 'betting_logs':{
                        const {size:count, page} = filter;
                        const url = `/admin/download/logs?${this.utils.toUrlSearchParams({count, page, search:filter})}`;
                        // return this.utils.get(`/admin/download/logs?${this.utils.toUrlSearchParams({count, page, search:filter})}`)

                        let a = document.createElement('a');
                        a.href = url;
                        a.target = '_blank';
                        a.click();
                    };
                    case 'game_history':{
                        return this.utils.get(`/admin/download/history?${this.utils.toUrlSearchParams(filter)}`)
                    }
                }
            }
        },
        form:{
            login(data){
                return this.fire.login(data);
            },
            getPermissions(){
                return this.fire.getPermissions();
            },
            addRecord(data){
                this.fire.addRecord(data);
            },
            getRecord(_id){
                return this.fire.getRecord(_id);
            },
            editRecord(data){
                this.fire.editRecord(data);
            },
            gameTypes(type){
                return this.fire.gameTypes(type); 
            },
        },
        getUserPermissions:{
            components:['sidebar','app','form','tabs','fuzzy'],
            handler(e){
                return this.fire.getUserPermissions(e);
            }
        },
        
        getRoles:{
            components:['form'],
            handler(e){
                return this.fire.getRoles();
            }
        },
        radio:{
            deleteRecord:function(e){
                this.fire.deleteRecord(e);
            },
            enableGame(e){
                this.fire.enableGame(e);
            },
            disableGame(e){
                this.fire.disableGame(e);
            },
            closeBet(bet_id){
                this.fire.closeBet(bet_id);
            }
        },
        getGGRSummary:{
            components:['table','ggr'],
            handler(e){
                this.fire.getGGRSummary(e);
            }
        },
        getBetSummary:{
            components:['table','bet_summary'],
            handler(e){
                
                this.fire.getBetSummary(e);
            }
        },
        token:{
            getStaticToken(){
                return this.fire.getStaticToken();
            },
            resetStaticToken(){
                return this.fire.resetStaticToken();
            }
        },
        upload:{
            getGameStatus(game){
                return this.fire.getGameStatus(game);
            }
        },
        axios:{
            getUploadUrl(){
                return this.fire.getUploadUrl();
            }
        },
        toolbar:{
            downloadRemote(){
                
                return this.fire.downloadRemote();
            }
        }
    },
});