Cake.create('select',null,{
    handlers:{
        renderSelectPermission(options){
            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-permission_id]',
                multiple: true,
                showValueAsTags: true,
                zIndex: 99,
                dropboxWrapper: 'body',
                options
            });
        },
        renderSelectPermissionID({options, selectedOptions}){
            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-permission_id]',
                multiple: true,
                showValueAsTags: true,
                zIndex: 99,
                dropboxWrapper: 'body',
                selectedValue:selectedOptions,
                options,
            });
        },
        renderSelectUsername(options){
            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-username]',
                multiple: true,
                showValueAsTags: true,
                zIndex: 99,
                dropboxWrapper: 'body',
                options
            });
        },
        renderSelectGameName(options){
            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-game_type]',
                multiple: true,
                showValueAsTags: true,
                zIndex: 99,
                dropboxWrapper: 'body',
                options
            });
        },
        renderGameType({options}){

            this.data.virtual = VirtualSelect.init({
                ele:'[data-name=virtual-select-game_type]',
                multiple: true,
                showValueAsTags: true,
                zIndex: 99,
                dropboxWrapper: 'body',
                options,
                name:'game_type',
            });
        },
    },
    subscribe:{
        form:{
            renderSelectPermissionID({options, selectedOptions}){

                this.fire.renderSelectPermissionID({options, selectedOptions});
            },
            renderSelectPermission(options){
                this.fire.renderSelectPermission(options);
            },
            async renderGameType(options){
                // console.log(67,this.data.hasGameType, options);
                if(!this.data.hasGameType){
                    this.data.hasGameType = true;
                    return this.fire.renderGameType(options);
                } else {
                    this.data.virtual && await this.data.virtual.destroy();

                    
                    return this.fire.renderGameType(options);
                }
            },
            selectedOptions(){
                return (this.data.virtual.getSelectedOptions()) || null;
            },
        }
    },
});