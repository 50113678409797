Cake.create('sidebar', '#sidebars', {
    root:'#sidebar-container',
    toggle:{
        activeSidebarMenu:{
            sel:`.sidebar-middle>div>a`, cls:'is-active', basis:'data-name',
        },
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        softReload(){
            console.log('sidebar soft reload');
        },
        destroy(){
            document.body.classList.remove('has-sidebar');
            return this.reset();
        },
        async isConnected(){
            document.body.classList.add('has-sidebar');

            let auth = this.$router.auth();
            if(!auth)return;
            let username = auth.data.username;
            let token = auth.data.token;

            

            let permission = await this.fire('getUserPermissions');

            // console.log(25, permission);

            this.data.permission = permission.filter(item=>{
                return item.name.includes('sidebar.');
            }).map(item=>{
                item.name = item.name.split('sidebar.')[1];
                return item;
            });
        

            await this.$scope.set('username',username);
            await this.$scope.set('sidebarLinks',this.data.permission);
            await this.toggler('activeSidebarMenu', this.data.menu);

        },
        click(e){

            let next = e.target.dataset.name;


            let menu = this.data.menu;
            this.toggler('activeSidebarMenu', next);
        },
    },
    subscribe:{

        app:{
            isConnected(){
                // this.render();
            }
        }
    } 
})