Cake.create('fuzzy_summary','#fuzzy-summary',{
    root:'.fuzzy-summary-container',
    data(){
        // let date_range = await this.$globalScope.get('date_range'); 
        // let channel_type = await this.$globalScope.get('channel_type'); 
        // let game_type = await this.$globalScope.get('game_type'); 
        // let game_type_selected = await this.$globalScope.get('game_type_selected'); 
        // let bet_filter = await this.$globalScope.get('bet_filter'); 
        this.conf = {
            ggr:['date_range','bet_filter','game_type_selected','start_date','end_date'],
            betting_logs:['date_range','bet_filter','game_type_selected','start_date','end_date'],
        }
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        async destroy(){
            await this.reset();
        },
        isConnected(e){

            let {emit:{filters}} = e;




            filters = filters.filter(item=>{
                let [k,v] = Object.entries(item)[0];
                return this.data.conf[this.data.menu].includes(k) && !!v;
            }).map(item=>{
                let [k,v] = Object.entries(item)[0];

                let conf = {id:k,display:v.split("_").join(" "), field:k.split("_").join(" ")};

                if(['bet_filter','date_range'].includes(k)){
                    conf.type = 'required';
                } else {
                    conf.type = 'optional';
                }

                return conf;
            });


            this.$scope.set('filters',filters);
        },
        click(e){
            let target = e.target;
            let tag = target.closest('.fuzzy-tags');
            if(tag){
                let field = tag.dataset.field;

                // console.log(field);

                if(['start_date','end_date'].includes(field)){
                    this.$globalScope.removeCache('start_date');
                    this.$globalScope.removeCache('end_date');

                    tag.parentElement.children.toArray().forEach(item=>{
                        let field = item.dataset.field;
                        if(['start_date','end_date'].includes(field)){
                            item.remove();
                        };
                    });

                } else {
                    this.$globalScope.removeCache(field);
                    tag.remove();
                };



                this.fire('updateFilters');
            };
        }
    },
    subscribe:{
        fuzzy:{
            async renderFuzzySummary(e){
                this.render({emit:{filters:e}});
            },
            async removeFuzzySummary(e){
                await this.fire.destroy();
            },
        }
    },
});