Cake.create('ggr', '#ggr', {
    root:'.summary-container',
    data(){
        this.search = {};
        
    },
    router(){
        this.data.menu = this.$router.name;
    },
    handlers:{
        destroy(){
            this.data.search = {};
            console.log(13, this.data.search);
            this.reset();
        },
        submit(e){
            e.preventDefault();
            let o = {};
            let form = e.target;
            let data = new FormData(form);
            for (let [key, value] of data.entries()){
                o[key] = value;
            };

            // this.fire('getGGRSummary', o);

            return o;
        },
        renderGGR(r){
            if(r && Object.keys(r).length < 4){
                r = {
                    total_bet_amount:0,
                    total_payout_amount:0,
                    amount_yield:0,
                    ggr_margin:0,
                }
            };
            this.$scope.set('total_bet_amount',accounting.formatMoney(r.total_bet_amount, {
                symbol:'PHP ',
                thousand:',',
                decimal:'.',
            }));
            this.$scope.set('total_payout_amount',accounting.formatMoney(r.total_payout_amount, {
                symbol:'PHP ',
                thousand:',',
                decimal:'.',
            }));
            this.$scope.set('amount_yield',accounting.formatMoney(r.amount_yield, {
                symbol:'PHP ',
                thousand:',',
                decimal:'.',
            }));
            let margin = accounting.formatMoney(r.ggr_margin, {
                symbol:'',
                thousand:',',
                decimal:'.',
            });
            this.$scope.set('ggr_margin', margin);
        },
        async updateFilters(){
            let filters = await this.fire('filters');
            this.data.search = {};
            // console.log(63, filters);
            filters.forEach(filter=>{
                let [key, value] = Object.entries(filter)[0];
                this.data.search[key] = value;
            });
            return filters;
        },
        async isConnected(e){
            await this.fire.updateFilters();
            await this.fire('getGGRSummary',{ search:this.data.search });
        }
    },
    subscribe:{
        sidebar:{
            renderggr(e){
                console.log('render ggr')
                this.render();
            },
            destroyggr(e){
                this.fire.destroy();
            },
        },
        api:{
            setGGR(obj){
                if (obj.data){
                    this.fire.renderGGR(obj.data);
                };
            }
        },

        fuzzy:{
            filterByDateRange(name){
                if(['ggr'].includes(this.data.menu)){
                    this.data.search.date_range = name;

                    this.fire('getGGRSummary',{search:this.data.search});
                };
            },

        },
        updateFilters:{
            components:['fuzzy','fuzzy_summary'],
            async handler(e){
                await this.fire.updateFilters();

                // console.log(107, this.data.search);

                await this.fire('getGGRSummary',{ search:this.data.search });
            }
        }
    },
});