Cake.create('header','#header',{
    root:'#header-container',
    handlers:{
        softReload(){
            console.log('header soft reload');
        },
        async isConnected(){
            let auth = this.$router.auth();
            if(!auth)return;
            
            let username = auth.data.username;
            await this.$scope.set('username',username);
        },
        logout(e){
            this.$router.logout();
        }
    },
    subscribe:{},
});